<template>
  <div>
    <v-card-title> {{ $t('externalDeliveryResults') }} </v-card-title>
    <v-list class="dense pt-0">
      <template v-for="(result, i) in results">
        <v-list-item :key="i">
          <v-list-item-content>
            <v-list-item-title>{{ result.namespace }}</v-list-item-title>
            <v-list-item-subtitle style="white-space: pre-wrap;">
              {{ result.created | moment("dddd, MMMM Do YYYY, HH:mm:ss") }} - {{ result.message }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon
              v-if="result.success"
              color="green"
            >
              mdi-check
            </v-icon>
            <v-icon
              v-else
              color="red"
            >
              mdi-alert
            </v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>

    export default {
        name: 'PaymentList',
        props: {
        results: {
            type: Array, 
            default: () => {}
        },
        },
    }
</script>
